<template>
  <el-card v-if="isGetData" id="trade-detail" class="box-card profile-card">
    <el-table
      ref="table"
      :data="getPointHistory"
      style="width: 100%"
      :default-sort="{ prop: 'date', order: 'descending' }"
      empty-text="尚無交易明細"
    >
      <el-table-column
        prop="transaction_time"
        label="日期"
        width="120"
        sortable
      >
        <template slot-scope="{ row }">
          <div v-html="wrapDate(row.transaction_time, 'break')"></div>
        </template>
      </el-table-column>
      <el-table-column
        prop="transaction_point"
        label="進帳/扣除儲值點數"
        tableHeight
        width="180"
      >
        <template slot-scope="{ row }">
          {{ wrapPoint(row.transaction_point) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="transaction_targetQ_point"
        label="進帳/扣除Target-Q點數"
        width="180"
      >
        <template slot-scope="{ row }">
          {{ wrapPoint(row.transaction_targetQ_point) }}
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="說明">
        <template slot-scope="{ row }">
          <div v-html="wrapReason(row.reason)"></div>
        </template>
      </el-table-column>
      <el-table-column
        prop="point"
        label="剩餘儲值點數"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="targetQ_point"
        label="剩餘Target-Q點數"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      v-if="isGetData"
      layout="prev, pager, next"
      :total="getHistoryCount"
      :page-size="getHistoryPageSize"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :hide-on-single-page="true"
    ></el-pagination>
  </el-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { wrapDate } from '@/utils/wrapDate';
export default {
  name: 'trade-detail',
  data() {
    return {
      isGetData: false,
      currentPage: 1,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters(['getPointHistory', 'getHistoryCount', 'getHistoryPageSize']),
    // tableHeight() {
    //   return window.innerHeight * 0.75;
    // },
  },
  methods: {
    ...mapActions(['doGetPointHistory']),
    wrapDate,
    handleCurrentChange(page) {
      // this.isGetData = false;
      this.currentPage = page;
      this.getData();
      this.$refs.table.bodyWrapper.scrollTop = 0;
    },
    async getData() {
      await this.doGetPointHistory(this.currentPage);
      this.isGetData = true;
    },
    wrapPoint(point) {
      if (point > 0) {
        return '+' + point;
      } else if (point === 0) {
        return '- -';
      } else {
        return '-' + point * -1;
      }
    },
    wrapReason(text) {
      return (
        text.split('：')[0] + '：<br/>' + text.slice(text.indexOf('：') + 1)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-card {
  margin-bottom: 15px;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04) !important;
  font-family: PingFangTC-Regular;
}
</style>
