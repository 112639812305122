var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isGetData)?_c('el-card',{staticClass:"box-card profile-card",attrs:{"id":"trade-detail"}},[_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.getPointHistory,"default-sort":{ prop: 'date', order: 'descending' },"empty-text":"尚無交易明細"}},[_c('el-table-column',{attrs:{"prop":"transaction_time","label":"日期","width":"120","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.wrapDate(row.transaction_time, 'break'))}})]}}],null,false,2357590090)}),_c('el-table-column',{attrs:{"prop":"transaction_point","label":"進帳/扣除儲值點數","tableHeight":"","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.wrapPoint(row.transaction_point))+" ")]}}],null,false,985780913)}),_c('el-table-column',{attrs:{"prop":"transaction_targetQ_point","label":"進帳/扣除Target-Q點數","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.wrapPoint(row.transaction_targetQ_point))+" ")]}}],null,false,4063955790)}),_c('el-table-column',{attrs:{"prop":"reason","label":"說明"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.wrapReason(row.reason))}})]}}],null,false,3419539011)}),_c('el-table-column',{attrs:{"prop":"point","label":"剩餘儲值點數","width":"180"}}),_c('el-table-column',{attrs:{"prop":"targetQ_point","label":"剩餘Target-Q點數","width":"180"}})],1),(_vm.isGetData)?_c('el-pagination',{attrs:{"layout":"prev, pager, next","total":_vm.getHistoryCount,"page-size":_vm.getHistoryPageSize,"current-page":_vm.currentPage,"hide-on-single-page":true},on:{"current-change":_vm.handleCurrentChange}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }